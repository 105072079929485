.van-notify {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px 16px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-wrap: break-word;
}
.van-notify--primary {
  background-color: rgba(25, 137, 250, 0.9);
}
.van-notify--success {
  background-color: rgba(7, 193, 96, 0.9);
}
.van-notify--danger {
  background-color: rgba(238, 10, 36, 0.9);
}
.van-notify--warning {
  background-color: rgba(255, 151, 106, 0.9);
}
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
