/* --- 覆盖vant ---------------------------- */
// Color Palette
@black: #000;
@white: #fff;
@gray-1: #f7f8fa;
@gray-2: #f2f3f5;
@gray-3: #ebedf0;
@gray-4: #dcdee0;
@gray-5: #c8c9cc;
@gray-6: #969799;
@gray-7: #646566;
@gray-8: #323233;
@red: #ee0a24;
@blue: #1989fa;
@orange: #ff976a;
@orange-dark: #ed6a0c;
@orange-light: #fffbe8;
@green: #07c160;

// Gradient Colors
@gradient-red: linear-gradient(to right, #ff6034, #ee0a24);
@gradient-orange: linear-gradient(to right, #ffd01e, #ff8917);

// Component Colors
@text-color: @gray-8;
@active-color: @gray-2;
@active-opacity: 0.7;
@disabled-opacity: 0.5;
@background-color: @gray-1;
@background-color-light: #fafafa;
@text-link-color: #576b95;

// Padding
@padding-base: 4px;
@padding-xs: @padding-base * 2;
@padding-sm: @padding-base * 3;
@padding-md: @padding-base * 4;
@padding-lg: @padding-base * 6;
@padding-xl: @padding-base * 8;

// Font
@font-size-xs: 10px;
@font-size-sm: 12px;
@font-size-md: 14px;
@font-size-lg: 16px;
@font-weight-bold: 500;
@base-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'Hiragino Sans GB',
'Microsoft Yahei', sans-serif;
@price-integer-font-family: Avenir-Heavy, PingFang SC, Helvetica Neue, Arial,
sans-serif;

// Border
@border-color: @gray-3;
@border-width-base: 1px;
@border-radius-sm: 5px;
@border-radius-md: 8px;
@border-radius-lg: 10px;
@border-radius-max: 999px;

@_notify-bg-opacity: 90%;
@notify-primary-background-color: fade(@blue, @_notify-bg-opacity);
@notify-success-background-color: fade(@green, @_notify-bg-opacity);
@notify-danger-background-color: fade(@red, @_notify-bg-opacity);
@notify-warning-background-color: fade(@orange, @_notify-bg-opacity);

// Tabbar
@tabbar-height: 50px;

// TabbarItem
@tabbar-item-icon-size: 24px;

// Toast
@toast-background-color: fade(@black, 50%);

// Tab
@tab-font-size: @font-size-md;

// Tabs
@tabs-bottom-bar-height: 3px;

// DropdownMenu
@dropdown-menu-height: 44px;
@dropdown-menu-title-font-size: @font-size-md;

// Search
@search-background-color: @gray-3;
@search-content-background-color: @white;

// Dialog
@dialog-width: 300px;
@dialog-border-radius: 10px;

// Popup
@popup-round-border-radius: 10px;

// Stepper
@stepper-border-radius: 3px;
@stepper-input-width: 50px;

// NoticeBar
@notice-bar-line-height: 18px;
@notice-bar-icon-size: 14px;
@notice-bar-icon-min-width: 18px;

// Switch
@switch-size: 22px;

// Calendar
@calendar-confirm-button-height: 40px;
@calendar-confirm-button-margin: 10px 0;

// Swipe

// Field
@field-label-width: 80px;
@field-icon-size: 18px;

// Uploader
@uploader-size: 80px;
@uploader-icon-size: 24px;
@uploader-icon-color: @gray-4;
@uploader-text-color: @gray-6;
@uploader-text-font-size: @font-size-sm;
@uploader-upload-border-radius: 4px;
@uploader-upload-background-color: @gray-1;
@uploader-upload-border-color: @gray-4;
@uploader-delete-color: @gray-6;
@uploader-delete-icon-size: 18px;
@uploader-delete-background-color: @white;
@uploader-file-background-color: @background-color;
@uploader-file-icon-size: 20px;
@uploader-file-icon-color: @gray-7;
@uploader-file-name-padding: 0 @padding-base;
@uploader-file-name-margin-top: @padding-xs;
@uploader-file-name-font-size: @font-size-sm;
@uploader-file-name-text-color: @gray-7;
@uploader-mask-background-color: fade(@gray-8, 50%);
@uploader-text-upload: 0;
